<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-350-px">
        <div class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://www.asset.pmnet.gq/data/file/dh/ff6a9351946af2b1b0dddc6b36fc66bf0e57b74d.webp');">
          <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-0 shadow-xl rounded-lg -mt-64">
            <div class="px-6">
              <div class="text-center mt-12">
                <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  Docker Mirrors
                </h3>
                <div class="text-sm leading-normal mt-0 mb-12 text-blueGray-400 font-bold uppercase">
                  <i class="fas fa-bolt mr-2 text-lg text-blueGray-400"></i>
                  让您的开发和部署如虎添翼
                </div>
                <div class="flex flex-wrap justify-center">
                  <div class="relative w-full items-stretch mr-2 lg:w-6/12">
                    <span
                      class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                      <i class="fab fa-docker"></i>
                    </span>
                    <input type="text" v-model="originUrl" placeholder="输入原镜像的描述名称"
                      class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10" />
                  </div>
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap ">
                  <div class="w-full px-4 justify-center">
                    <div v-if="!showReadme" class="justify-center mx-1 px=1">
                      <div class="relative container mx-auto w-full">
                        <span
                          class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center pl-3 py-3">
                          <i class="fas fa-download"></i>
                        </span>
                        <input type="text" id="proxiedUrl" placeholder="代理后的镜像标签" :value="proxiedUrl" disabled
                          class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline lg:w-8/12 w-10/12 pl-10" />
                        <button data-clipboard-target="#proxiedUrl" id="copyProxied"
                          class="ml-1 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-1/12"
                          type="button">
                          <i class="fas fa-copy"></i>
                        </button>
                      </div>
                      <div class="mt-10 w-full flex flex-wrap relative justify-center container items-center">
                        <h4
                          class="relative lg:w-8/12 w-10/12 text-center text-3xl font-normal leading-normal mt-0 mb-0 text-blueGray-800">
                          <i class="fas fa-code"></i>
                          或者
                        </h4>
                        <code class="mt-10 relative lg:w-8/12 w-10/12 text-left">docker pull {{ proxiedUrl }}</code>
                        <code
                          class="relative lg:w-8/12 w-10/12 text-left">docker tag {{ proxiedUrl }} {{ originUrl }}</code>
                        <code class="relative lg:w-8/12 w-10/12 text-left">docker rmi {{ proxiedUrl }}</code>
                      </div>
                    </div>
                    <div v-else class="text-left justify-center flex flex-wrap container  mx-auto px-4 lg:w-9/12">
                      <div v-if="!isUrlValid" class="text-white px-6 py-4 border-0 rounded relative mb-6 bg-red-500">
                        <span class="text-base inline-block mr-5 align-middle">
                          <i class="fas fa-bell"></i>&nbsp;
                          <span class="inline-block align-middle mr-8">
                            当前输入的镜像标签格式有误或暂时不被支持
                          </span>
                        </span>
                      </div>

                      <h4 class="w-full text-3xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
                        <i class="fas fa-question"></i>
                        使用方法
                      </h4>
                      <p class="w-full text-base font-light leading-relaxed mt-0 mb-4 text-blueGray-800">
                        输入要拉取的镜像标签，如 <code>nginx</code>、<code>alpine:3.17</code>、<code>quay.io/calico/node</code>
                        等，在进行拉取操作时使用生成的镜像标签即可。
                        <br />
                        也可以使用生成的命令，但这只适用于 Docker 命令行用户，其他用户请按此逻辑自行作相应更改。
                      </p>
                      <h4 class="w-full text-3xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
                        <i class="far fa-thumbs-up"></i>
                        修改 daemon.json
                      </h4>
                      <p class="w-full text-base font-light leading-relaxed mt-0 mb-2 text-blueGray-800">
                        如果你的需求仅仅是加速来自 Docker Hub 的镜像，你可以考虑配置 <code>daemon.json</code>。在正确配置后，你只需要如往常一样使用
                        <code>docker pull</code> 命令，即可享受加速，而无需再对镜像进行重置标签的操作。
                      </p>
                      <pre class="w-full whitespace-pre-wrap text-base flex flex-wrap font-light mt-0 mb-4 text-blueGray-800">
{
  "registry-mirrors": [
    "https://docker.{{ crproxyRootDomain }}"
  ]
}</pre>
                      <h4 class="w-full text-3xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
                        <i class="fas fa-list-ol"></i>
                        支持的容器注册表
                      </h4>
                      <ul class="w-full text-base font-light leading-relaxed mt-0 mb-4 text-blueGray-800">
                        <li>Docker Hub（<code>docker.io</code>）</li>
                        <li>GitHub Container Registry（<code>ghcr.io</code>）</li>
                        <li>Google Container Registry（<code>gcr.io</code>）</li>
                        <li>Kubernetes（<code>registry.k8s.io</code> 和 <code>k8s.gcr.io</code>）</li>
                        <li>Red Hat Quay.io（<code>quay.io</code>）</li>
                      </ul>
                      <p class="w-full text-base font-light leading-relaxed mt-0 mb-4 text-blueGray-800">
                        如果你想要的容器注册表不在此列表中，欢迎通过页面下方的联系方式联系我们！
                      </p>
                      <div class="w-full text-white px-6 py-4 border-0 rounded relative mb-4 bg-teal-500">
                        <span class="text-xl inline-block mr-5 align-middle">
                          <i class="fas fa-bell"></i>&nbsp;&nbsp;
                        </span>
                        <span class="inline-block align-middle mr-8">
                          此项目正在开发和建设中，我们可能会引入一些破坏性更改！
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import clipboardJS from 'clipboard';


var crproxyRootDomain = "crp.getcr.net"

function processDockerHubOfficialImage(url = "") {
  if (url.includes("/")) {
    return url
  } else {
    return `library/${url}`
  }
}

export default {
  data() {
    return {
      originUrl: "",
      crproxyRootDomain: crproxyRootDomain,
    };
  },
  computed: {
    showReadme() {
      if (this.originUrl.trim() == "") {
        return true;
      }
      if (!this.isUrlValid) {
        return true;
      }
      return false;
    },
    isUrlValid() {
      let originUrl = this.originUrl.trim();
      var checkWhetherUrlValid = /^(?:docker\.io|ghcr\.io|gcr\.io|registry\.k8s\.io|k8s\.gcr\.io|quay\.io)/
      if (originUrl.includes(".")) {
        return checkWhetherUrlValid.test(originUrl)
      }
      return true;
    },
    proxiedUrl() {
      let crRegex = /(?:docker\.io|ghcr\.io|gcr\.i o|k8s\.gcr\.io|registry\.k8s\.io|quay\.io)\//;
      let originUrl = this.originUrl.trim();
      if (originUrl == "") {
        return "";
      }
      let s = originUrl.match(crRegex);
      if (s != null) {
        switch (s[0]) {
          case "docker.io/": {
            let processedUrl = processDockerHubOfficialImage(originUrl.replace("docker.io/", ""));
            return `docker.${crproxyRootDomain}/${processedUrl}`;
          }
          case "ghcr.io/":
            return originUrl.replace("ghcr.io", `ghcr.${crproxyRootDomain}`);
          case "gcr.io/":
            return originUrl.replace("gcr.io", `gcr.${crproxyRootDomain}`);
          case "registry.k8s.io/":
            return originUrl.replace("k8s", `k8s.${crproxyRootDomain}`);
          case "k8s.gcr.io/":
            return originUrl.replace("k8s.gcr.io", `k8s-gcr.${crproxyRootDomain}`);
          case "quay.io/":
            return originUrl.replace("quay.io", `quay.${crproxyRootDomain}`);
        }
      }
      return `docker.${crproxyRootDomain}/${processDockerHubOfficialImage(originUrl)}`;
    },
  },
  components: {
    Navbar,
    FooterComponent,
  },
  mounted() {
    let clipboard = new clipboardJS('#copyProxied')
    clipboard.on('success', () => {
      this.$noty.success('复制成功'
      )
    })
    clipboard.on('error', () => {
      this.$noty.error('复制失败，请手动复制')
    })
  }
};
</script>
